import React, { useState, useEffect } from "react"
import { Link, graphql, navigate } from "gatsby"
import styled from 'styled-components';
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Text from "../components/text"
import PortableText from "@sanity/block-content-to-react"
import serializer from "../utils/serializer"
import sanitizeHtml from 'sanitize-html';
import { 
  breakpoint,
  margin, 
  type_title,
  type_title_md,
  type_title_sm,
  type_body_sm,
  type_body,
  type_body_bold,
  baseColor, 
  greyColor, 
  blackColor, 
  grid, 
  colorTransition 
} from '../styles/helpers'
import { 
  PageWrap,
  PageWrapInner,
  PageEntry,
  PageEntryInner
} from '../styles/page'

const BookingsList = styled.ul`
  display: flex;
  flex-flow: column;
  width: 50%;
  @media ${breakpoint.xs} {
    width: 100%;
  }
  & li {
    list-style: none;
    margin-bottom: 2em;
    ${type_title_sm};
    border: solid black 1px;
    display: flex;
    &:hover {
      background: ${blackColor};
      & a {
        color: white !important;
      }
    }
    transition: background .2s linear !important;
    & a {
      transition: color .2s linear !important;
      padding: 1rem;
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: column;
      white-space: pre-wrap;
      color: ${blackColor};
    }
    &:hover {
      background: ${blackColor};
      & a {
        color: white;
      }
    }
  }
`

const BookingDate = styled.div`
  ${type_body};
  margin-bottom: .5em;
`

const BookingTitle = styled.div`
  ${type_title_sm};
`

const BookingMessage = styled.p`
  ${type_body};
  margin-bottom: 1em !important;
  // font-size: 80% !important;
  &:first-child {
    ${type_body_bold};
    // font-size: 80% !important;
  }
  &:last-child {
    margin-bottom: 2em !important;
  }
`


export const query = graphql`
  {
    allSanityBooking {
      edges {
        node {
          title
          formatted_title
          bookingDate
          formatted_date
          id
          slug {
            _key
            _type
            current
          }
          booking_session_url
        }
      }
    }
  }
`



const BookingPage = ({ data, location, ...props }) => {

  const [loaded, setLoaded] = useState(false)

  const handleCloseClick = (event) => {
    event.preventDefault()
    if (!props.prevPage.length || props.prevPage === null) {
      navigate('/')
    }
    else {
      navigate(-1)
    }
  }

  const handleMount = (event) => {
    setLoaded(true)
  }

  const handleUnmount = (event) => {
  }

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 250)
    // returned function will be called on component unmount 
    return () => {
      window.removeEventListener('load', handleUnmount)
    }
  }, [])

  
  // order booking by date ascending
  const orderedBookings = data.allSanityBooking.edges.sort((a, b) => a.date < b.date ? -1 : (a.date > b.date ? 1 : 0))

  return (
    <>
    <SEO title="Booking" />
    <PageWrap>
      <PageWrapInner>
        <PageEntry noPadding={true}>
          <PageEntryInner>
            <BookingMessage>Why do we ask for a financial contribution?</BookingMessage>
            <BookingMessage>We ask participants for a financial contribution when the event is not (fully) funded and/or requires a percentage of co-funding (as is often the case). The contributions made therefore cover the remaining costs of said event and of running our platform.</BookingMessage>
            <BookingMessage>We relate to the precarious conditions many researchers and students find themselves in today and we try our best to account for them through our sliding scale model. The model has three options for registration: a regular ticket, a discounted ticket, and a limited number of free tickets that we issue upon request.</BookingMessage>
            <BookingMessage>Free tickets are allotted on a first-come, first-served basis. So, please don’t request a free ticket when you can afford contributing. In case you obtain a free ticket and are not able to attend, please contact us at: info@thedormantoffice so we can pass on your ticket (on time) to another person on our waiting list.</BookingMessage>
          </PageEntryInner>
        </PageEntry>

        <BookingsList>
          {
            orderedBookings.map((booking, key) => 
            (
              <li>
                <Link to={`/booking/${booking.node.slug.current}`}>
                  <BookingDate>{sanitizeHtml(booking.node.formatted_date)}</BookingDate>
                  <BookingTitle>{sanitizeHtml(booking.node.formatted_title)}</BookingTitle>
                </Link>
              </li>
            ))
          }
        </BookingsList>
      </PageWrapInner>
    </PageWrap>
    </>
  )
}

export default BookingPage
